<template>
  <div v-if="qualityControlStatus === 'success'" class="d-flex flex-column">
    <module-navigation-bar title="Quality Control">
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div
      v-if="qualityControlStatus === 'success'"
      class="flex-1 d-flex overflow-y-auto"
    >
      <v-navigation-drawer permanent>
        <v-subheader>Phases</v-subheader>

        <v-list dense>
          <v-list-item-group v-model="phaseListIndex" color="primary">
            <v-list-item
              v-for="task in qualityControlPhaseTasks"
              :key="task.id"
              link
              @click="focusOnPhase(task)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ task.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <div
        class="d-flex flex-column flex-1 pos-rel overflow-x-hidden"
        style="width: 0"
      >
        <v-progress-linear
          :style="{ opacity: isLoading ? 1 : 0 }"
          height="3px"
          indeterminate
          color="primary"
        />
        <div style="height: 200px" class="d-flex">
          <div class="flex-1 ma-4 ant-glass-background">
            <apexchart
              type="radialBar"
              height="200"
              :options="completedChart.chartOptions"
              :series="completedChart.series"
            ></apexchart>
          </div>
          <div class="flex-1 ma-4 ant-glass-background">
            <apexchart
              class="flex-1"
              type="radialBar"
              height="200"
              :options="formChart.chartOptions"
              :series="formChart.series"
            ></apexchart>
          </div>
          <div class="flex-1 ma-4 ant-glass-background">
            <apexchart
              class="flex-1"
              type="radialBar"
              height="200"
              :options="documentChart.chartOptions"
              :series="documentChart.series"
            ></apexchart>
          </div>
          <div class="ma-4" style="flex: 2">
            <apexchart
              type="treemap"
              height="180"
              :options="measurementTreeMap.chartOptions"
              :series="measurementTreeMap.series"
            ></apexchart>
          </div>
          <div
            class="d-flex align-center justify-center flex-1 flex-column pa-5"
          >
            <v-btn
              color="primary"
              tile
              small
              class="mb-5 full-width"
              elevation="0"
              >Leanforms actions</v-btn
            >
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  small
                  class="full-width"
                  elevation="0"
                  tile
                  v-on="on"
                >
                  Export dossier
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="exportDossier('export_bg')">
                  <v-list-item-title>Bevoegd gezag</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportDossier('warranty')">
                  <v-list-item-title>Garantie</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportDossier('contract')">
                  <v-list-item-title>Contract</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="flex-1 overflow-x-auto overflow-y-auto pa-5">
          <delete-dialog
            :dialog="appendixDeleteDialog"
            :title="
              appendixDeleteItem
                ? `Are you sure you want to delete ${appendixDeleteItem.name}.${appendixDeleteItem.extension}`
                : ''
            "
            @closeDialog="
              appendixDeleteItem = null;
              appendixDeleteDialog = false;
            "
            @deleteAction="removeTaskAppendix"
          />
          <v-expansion-panels accordion flat multiple>
            <v-expansion-panel
              v-for="measurement in measurements"
              :key="measurement.id"
            >
              <v-expansion-panel-header>
                <div>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        measurement.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        measurement.type
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <template #actions>
                  <div class="d-flex align-center justify-center">
                    <span>
                      {{
                        measurement.children.filter((s) => s.status !== 'open')
                          .length
                      }}/{{ measurement.children.length }}
                    </span>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="selectedMeasurement = measurement"
                        >
                          <v-icon>mdi-export</v-icon>
                        </v-btn>
                      </template>
                      <span>View Measurement</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="pl-10">
                <div
                  v-for="task in measurement.children"
                  :key="task.id"
                  class="d-flex flex-column mb-2"
                >
                  <div class="d-flex align-center">
                    <v-icon v-if="task.status === 'closed'" color="success"
                      >mdi-checkbox-marked-circle-outline
                    </v-icon>
                    <v-icon
                      v-else-if="task.status === 'canceled'"
                      color="warning"
                      >mdi-alert-rhombus-outline
                    </v-icon>
                    <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon class="mx-2">{{
                      task?.task_type?.custom_2 === 'leanform'
                        ? 'mdi-receipt-text-arrow-right'
                        : 'mdi-file'
                    }}</v-icon>
                    <v-chip style="width: 100px" class="justify-center mr-5">{{
                      task.sbscode.code
                    }}</v-chip>
                    {{ task.title }}
                    <v-spacer />
                    <div class="d-flex" style="gap: 8px">
                      <v-file-input
                        :id="`${task.id}-file-input`"
                        multiple
                        class="pa-0 ma-0"
                        style="height: 0; opacity: 0"
                        hide-input
                        @change="uploadAppendixes"
                      />

                      <v-btn
                        v-if="
                          task.task_type.custom_2 === 'document' &&
                          task.status === 'open'
                        "
                        small
                        color="info"
                        elevation="0"
                        @click.stop="openUploadAppendix(task)"
                        ><v-icon>mdi-file-upload</v-icon> Upload</v-btn
                      >

                      <v-btn
                        v-if="
                          task.task_type.custom_2 === 'task' &&
                          task.status === 'open'
                        "
                        small
                        elevation="0"
                        color="success"
                        @click.stop="closTask(task.id)"
                        ><v-icon>mdi-check-circle-outline</v-icon
                        >Complete</v-btn
                      >

                      <v-btn
                        v-if="
                          task.task_type.custom_2 === 'document' &&
                          task.appendixes.length > 0 &&
                          task.status === 'open'
                        "
                        small
                        elevation="0"
                        color="success"
                        @click.stop="closTask(task.id)"
                        ><v-icon>mdi-check-circle-outline</v-icon
                        >Complete</v-btn
                      >

                      <v-btn
                        v-if="task.task_type.custom_2 === 'leanform'"
                        elevation="0"
                        color="info"
                        small
                        @click.stop="openLeanformForm(task.id)"
                        ><v-icon>mdi-play-box</v-icon>Leanforms</v-btn
                      >
                      <v-chip
                        v-if="task.task_type.custom_9 === 'acties_vereist'"
                        color="warning"
                        small
                      >
                        <v-icon>mdi-clipboard-alert</v-icon>
                        Acties vereist
                      </v-chip>
                      <v-chip
                        v-else-if="task.task_type.custom_10"
                        color="info"
                        small
                      >
                        <v-icon>mdi-clipboard-clock</v-icon>In progress
                      </v-chip>
                    </div>
                  </div>
                  <div v-if="task.appendixes.length > 0">
                    <v-subheader>Files</v-subheader>
                    <div class="d-flex flex-wrap">
                      <v-tooltip
                        v-for="appendix in task.appendixes"
                        :key="appendix.id"
                        bottom
                      >
                        <template #activator="{ on }">
                          <div
                            style="height: 60px"
                            class="task-appendix ant-glass-background mx-1 d-flex align-center justify-center pa-2"
                            v-on="on"
                            @click="
                              downloadAppendix(appendix.task, appendix.id)
                            "
                          >
                            <v-icon
                              v-if="task.status === 'open'"
                              small
                              class="ant-icon"
                              style="position: absolute; right: 5px; top: 5px"
                              @click.stop="setupAppendixDelete(appendix)"
                              >mdi-delete
                            </v-icon>
                            <div
                              class="d-flex flex-column full-width align-center justify-center"
                            >
                              <v-icon small>mdi-file</v-icon>
                              <div
                                class="text-no-wrap overflow-hidden"
                                style="text-overflow: ellipsis"
                              >
                                {{ appendix.name }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <span
                          >{{ appendix.name }}.{{ appendix.extension }}</span
                        >
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div
          :class="{ 'show-risk-detail': selectedMeasurement }"
          style="top: 0; right: 0; z-index: 10"
          class="ant-glass-background radius-0 pa-4 pos-abs full-height risk-detail-container"
        >
          <v-icon @click="selectedMeasurement = null">mdi-close</v-icon>
          <div
            v-for="headChapterItem in headChapters"
            :key="headChapterItem.id"
            class="px-1 py-1"
          >
            <v-subheader
              style="height: auto; font-size: 16px"
              class="analysis-chapter"
            >
              Chapter {{ headChapterItem.chapter }}.
              {{ headChapterItem.title }}
            </v-subheader>
            <div class="pl-3 py-2">
              <div
                v-for="subChapterItem in subChapters.filter(
                  (subChapter) => subChapter.chapter === headChapterItem.chapter
                )"
                :key="subChapterItem.id"
              >
                <v-subheader style="height: auto" class="analysis-chapter">
                  {{ subChapterItem.chapter }}.{{ subChapterItem.sub_chapter }}:
                  {{ subChapterItem.title }}
                </v-subheader>
                <div class="py-1 pl-3">
                  <div
                    v-for="risk in measurementRisks.filter(
                      (risk) =>
                        risk.chapter === subChapterItem.chapter &&
                        risk.sub_chapter === subChapterItem.sub_chapter
                    )"
                    :key="risk.id"
                    style="font-size: 12px"
                    class="d-flex align-center py-1 px-2 analysis-risk-item"
                  >
                    <div>
                      {{ risk.friendly_id }}
                    </div>
                    <v-divider class="mx-2" vertical />
                    <div class="mr-2" style="min-width: 40px">
                      {{ risk.chapter }}.{{ risk.sub_chapter }}.{{
                        risk.number ? risk.number : '-'
                      }}
                    </div>
                    <div>
                      {{ risk.name }}
                    </div>
                    <v-spacer />
                    <div class="d-flex justify-end">
                      <div class="font-italic" style="min-width: 50px">
                        {{ risk.NLSFB ? risk.NLSFB : '-' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-divider />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center flex-1">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import { DHME_QUALITY_CONTROL } from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import FileHandler from '@/services/file-handler';
import {
  closeTask,
  downloadTaskAppendix,
  uploadTaskAppendix,
} from '@/services/api/task.api';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default {
  name: 'DhmeWkbQualityControl',
  components: {
    DeleteDialog,
    ModuleNavigationBar,
    AntLoading,
  },
  data: () => {
    return {
      phaseListIndex: null,
      focusedPhase: null,
      measurements: [],
      appendixDeleteDialog: false,
      appendixDeleteItem: null,
      defaultChartOptions: {},
      completedChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 150,
            type: 'radialBar',
            offsetY: -10,
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '14px',
                  color: '#888',
                  offsetY: 60,
                },
                value: {
                  offsetY: -10,
                  fontSize: '12px',
                  color: '#888',
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
          stroke: {
            dashArray: 2,
          },
          labels: ['Total'],
        },
      },
      formChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 150,
            type: 'radialBar',
            offsetY: -10,
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '14px',
                  color: '#888',
                  offsetY: 60,
                },
                value: {
                  offsetY: -10,
                  fontSize: '12px',
                  color: '#888',
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91],
            },
          },
          stroke: {
            dashArray: 2,
          },
          labels: ['Forms'],
        },
      },
      documentChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 150,
            type: 'radialBar',
            offsetY: -10,
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '14px',
                  color: '#888',
                  offsetY: 60,
                },
                value: {
                  offsetY: -10,
                  fontSize: '12px',
                  color: '#888',
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91],
            },
          },
          stroke: {
            dashArray: 2,
          },
          labels: ['Documents'],
        },
      },
      measurementTreeMap: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          legend: {
            show: false,
          },
          chart: {
            height: 177,
            type: 'treemap',
            offsetY: -15,
            toolbar: {
              show: false,
            },
          },
        },
      },
      selectedMeasurement: null,
      appendixTask: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'authenticatedUser',
      'project',
      'qualityControlData',
      'qualityControlStatus',
      'qualityControlPhaseTasks',
      'qualityControlRisks',
      'qualityControlChapters',
      'qualityControlMeasurements',
      'qualityControlMeasurementTasks',
      'qualityControlMeasurementForms',
      'qualityControlMeasurementDocuments',
      'qualityControlProjectMeasurements',
      'selectedSbsObject',
    ]),
    headChapters() {
      if (this.selectedMeasurement) {
        return this.qualityControlChapters.filter((risk) =>
          this.measurementRisks.some(
            (tmp) => tmp.chapter === risk.chapter && !risk.sub_chapter
          )
        );
      }
      return [];
    },
    subChapters() {
      if (this.selectedMeasurement) {
        return this.qualityControlChapters.filter((risk) =>
          this.measurementRisks.some(
            (tmp) =>
              tmp.chapter === risk.chapter &&
              risk.sub_chapter === tmp.sub_chapter
          )
        );
      }
      return [];
    },
    measurementRisks() {
      if (this.selectedMeasurement) {
        return this.qualityControlRisks.filter((risk) =>
          this.qualityControlProjectMeasurements.some(
            (relation) =>
              relation.risk_id === risk.id &&
              relation.measurement_id === this.selectedMeasurement.id
          )
        );
      }
      return [];
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_QUALITY_CONTROL
      ).id;
    },
  },
  watch: {
    selectedSbsObject(value) {
      this.setMeasurements();
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchQualityControlData', {
      projectId: this.project.id,
      moduleId: this.moduleId,
      sessionId: this.$route.params.sessionId ?? null,
    });
    this.completedChart.chartOptions = {
      fill: {
        colors: [this.$vuetify.theme.themes.light.primary],
      },
    };
    this.formChart.chartOptions = {
      fill: {
        colors: [this.$vuetify.theme.themes.light.primary],
      },
    };
    this.documentChart.chartOptions = {
      fill: {
        colors: [this.$vuetify.theme.themes.light.primary],
      },
    };
    this.measurementTreeMap.chartOptions = {
      colors: [this.$vuetify.theme.themes.light.primary],
    };
  },
  methods: {
    downloadAppendix(taskId, appendixId) {
      downloadTaskAppendix(taskId, appendixId).then((response) => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          `data:${response.mimetype};base64,${response.file}`
        );
        element.setAttribute(
          'download',
          `${response.name}.${response.extension}`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
    },
    setupAppendixDelete(appendix) {
      this.appendixDeleteItem = appendix;
      this.appendixDeleteDialog = true;
    },
    removeTaskAppendix() {
      this.$store
        .dispatch('deleteTaskAppendix', {
          taskId: this.appendixDeleteItem.task,
          appendixId: this.appendixDeleteItem.id,
        })
        .then(() => {
          this.appendixDeleteItem = null;
          this.appendixDeleteDialog = false;
        });
    },
    focusOnPhase(task) {
      this.focusedPhase = task;
      this.setMeasurements();
    },
    async setMeasurements() {
      this.isLoading = true;
      let uniqueIds = [
        ...new Set(
          this.qualityControlProjectMeasurements.map(
            (relation) => relation.measurement_id
          )
        ),
      ];

      let { tasks } = await queryTasksV2(this.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-quality-assurance'],
        },
        {
          column: 'parent',
          operator: '=',
          values: [this.focusedPhase.id],
        },
      ]);

      let measurements = this.qualityControlMeasurements.filter((measurement) =>
        uniqueIds.some((id) => measurement.id === id)
      );
      for (const measurement of measurements) {
        let measurementTasks = this.qualityControlMeasurementTasks.filter(
          (item) => item.measurement_id === measurement.id
        );
        let measurementForms = this.qualityControlMeasurementForms.filter(
          (item) => item.measurement_id === measurement.id
        );
        let measurementDocuments =
          this.qualityControlMeasurementDocuments.filter(
            (item) => item.measurement_id === measurement.id
          );
        let measurementTaskItems = tasks.filter(
          (task) =>
            measurementTasks.some(
              (form) => form.id === task.task_type.custom_1
            ) ||
            measurementForms.some(
              (form) => form.id === task.task_type.custom_1
            ) ||
            measurementDocuments.some(
              (document) => document.id === task.task_type.custom_1
            )
        );
        if (measurementTaskItems.length > 0) {
          measurement.hasChildren = true;
          if (this.selectedSbsObject) {
            measurement.children = measurementTaskItems
              .filter(
                (task) => task.sbscode?.code === this.selectedSbsObject.code
              )
              .map((t) => {
                return {
                  collapsed: true,
                  children: [],
                  ...t,
                };
              });
          } else {
            measurement.children = measurementTaskItems.map((t) => {
              return {
                collapsed: true,
                children: [],
                ...t,
              };
            });
          }
        } else {
          measurement.hasChildren = false;
          measurement.children = [];
        }
        measurement.collapsed = false;
        measurement.relations = [];
        measurement.labels = [];
      }
      this.measurements = measurements.filter((m) => m.children.length > 0);
      this.setChartData(tasks);
      this.isLoading = false;
    },
    async openLeanformForm(taskId) {
      let response = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchLeanformLink',
        {
          task: taskId,
        }
      );

      if (response.url) {
        window.open(response.url, '__blank');
      } else {
        this.$store.commit('showNotification', {
          content: 'Leanforms url not found, please contact support',
          color: 'error',
        });
      }
    },
    async uploadAppendixes(files) {
      for (const file of files) {
        let appendix = await FileHandler.handleFile(file);

        this.appendixTask = await uploadTaskAppendix(
          this.appendixTask.id,
          appendix
        );

        this.measurements.forEach((m) => {
          if (m.children.find((t) => t.id === this.appendixTask.id)) {
            Object.assign(
              m.children.find((t) => t.id === this.appendixTask.id),
              this.appendixTask
            );
          }
        });

        this.measurements = [...this.measurements];
      }
    },
    openUploadAppendix(task) {
      document.getElementById(`${task.id}-file-input`).click();
      this.appendixTask = task;
    },
    async closTask(id) {
      const task = await closeTask(id);

      this.measurements.forEach((m) => {
        if (m.children.find((t) => t.id === task.id)) {
          Object.assign(
            m.children.find((t) => t.id === task.id),
            task
          );
        }
      });

      this.measurements = [...this.measurements];
    },
    exportDossier(exportType) {
      let forms = this.qualityControlMeasurementForms.filter(
        (form) =>
          this.measurements
            .flatMap((item) => item.children)
            .some((task) => task.task_type.custom_1 === form.id) &&
          form[exportType]
      );
      if (forms.length > 0) {
        executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'exportDossier',
          {
            form_ids: forms.map((item) => item.id),
          }
        );
      } else {
        this.$store.commit('showNotification', {
          content: 'No forms found for this category',
          color: 'warning',
        });
      }

      // TODO notify when download is ready like bom export
    },
    setChartData(tasks) {
      let totalPercent = Math.ceil(
        (tasks.filter((task) => task.status === 'closed').length /
          tasks.length) *
          100
      );

      let formTasks = tasks.filter((task) =>
        this.qualityControlMeasurementForms.some(
          (measurement) => measurement.id === task.task_type.custom_1
        )
      );
      let formPercent = Math.ceil(
        (formTasks.filter((task) => task.status === 'closed').length /
          formTasks.length) *
          100
      );

      let documentTasks = tasks.filter((task) =>
        this.qualityControlMeasurementDocuments.some(
          (measurement) => measurement.id === task.task_type.custom_1
        )
      );
      let documentPercent = Math.ceil(
        (documentTasks.filter((task) => task.status === 'closed').length /
          documentTasks.length) *
          100
      );

      this.completedChart.series = [totalPercent];
      this.formChart.series = [formPercent];
      this.documentChart.series = [documentPercent];

      // set treemap
      let data = [];
      this.measurements.forEach((measurement) => {
        data.push({
          x: measurement.name,
          y: measurement.children.length,
        });
      });
      this.measurementTreeMap.series = [
        {
          data: data,
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
.selected-measurement {
  border-left: solid 4px var(--v-primary-base);
}

.ant-gantt-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}

.risk-detail-container {
  transform: translateX(400px);
  transition: transform 200ms ease-out;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  &.show-risk-detail {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
}


.task-appendix {
  font-size: 10px;
  cursor: pointer;
  transition: 200ms;

  .ant-icon {
    opacity: 0;
  }



  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .ant-icon {
      opacity: 1;
    }
  }
}
</style>
